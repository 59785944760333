.duration {
  max-width: 210px;
}
.dialogWidth {
  max-width: 757px !important;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  //MOBILE VIEW
  @media (max-width: 767px) {
    overflow: auto !important;
  }
  overflow: hidden !important;
}
@media (max-width: 767px) {
  .dialogWidth {
    max-width: 100% !important;
  }
}
