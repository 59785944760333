.userGroupBlockInfo {
  min-height: 500px;
  height: 100%;
  margin-right: 20px;
}

@media (max-width: 3000px) {
  .authenticate {
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 991px) {
  .authenticate {
    flex-wrap: wrap !important;
  }
}

@media (max-width: 767px) {
  .customerProfile .loginAs {
    position: relative;
    top: 0;
    right: 0;
  }
}

// .customerProfile ul li:last-child {
//   display: flex;
//   justify-content: flex-end;
// }

.loginAs {
  position: absolute;
  top: 22px;
  right: 25px;
}

.loginSessions {
  top: 9px;
}

.userProfileCompanyList > div {
  flex-direction: inherit !important;
  a {
    margin-bottom: 10px;
  }
  .showMoreBtn {
    width: 100%;
  }
}
