.carousel {
  &:first-child [data-bs-target] {
    width: 15px !important;
    height: 15px !important;
    border-radius: 50%;
  }
  &:first-child [aria-current='true'] {
    background-color: skyblue;
  }
}
