.youtubePlayerPopup iframe {
  pointer-events: auto;
}

.actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90%;

}