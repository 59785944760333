@media (min-width: 992px) {
  .aside-fixed .aside {
    transform: initial !important;
  }

  .drawer-overlay {
    display: none;
  }
}
.menu-item.mb-4.active {
  font-weight: 600;
}