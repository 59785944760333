.canvaImage{
  max-width: 800px;
  max-height: 400px;
  object-fit: contain;
  margin: 5px;
}
.buttonStyle{
  margin-top: 16px;
  min-width: 100px;
  margin-right: 24px;
  padding-top: 1px;
}