.noPreview {
 object-fit: contain;
}

.html5 {
 color: #e34d25;
 transform: scale(2.6);
 margin-left: 21px;
 margin-right: 20px;
 margin-top: -6px;
}
