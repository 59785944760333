.dialog {
  width: 100vw !important;
  max-width: 800px !important;
}

.preview {
  @extend .dialog;

  height: 56vw !important;
  max-height: 450px !important;
  padding-bottom: 0 !important;
}
