$item-color: #7239ea;

@mixin item-item {
  $item-margin: 5px;

  width: calc(50% - #{$item-margin} - 1px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;

  &:nth-child(odd) {
    margin-right: $item-margin;
  }
}

@mixin item {
  border: 1px solid $item-color;
  color: $item-color;
  border-radius: 6px;
}

.item {
  @include item-item;
  @include item;

  padding: 2px 13px;
  margin: 0 2px;
}

.singleItem {
  @include item;

  display: flex;
  min-height: 27px;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  height: 100%;
  min-width: 112px;
}

.showMoreBtn {
  @include item-item;

  line-height: 30px;

  &.clickableItem {
    color: $item-color;
  }
}
