.img {
  width: 1.2rem;
  height: 1.5rem;
}

.link {
  font-size: 1.1rem;
  text-decoration: underline;
  font-weight: 500;
  color: #1825aa;
  &:hover {
    text-decoration: underline !important;
  }
}

.previewImage {
  object-fit: cover;
}

.textHeight {
  min-height: 85px;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}
.freeSkuText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  min-height: 37.75px;
  margin-bottom: 0.5rem
}
.skuText {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reverseButtonDesign {
  border: 1px solid #1c24ac !important;
  i {
    color: #1c24ac;
  }
  &:hover {
    color: #fff !important;
    i {
      color: #fff !important;
    }
  }
}
.videoIconPlay {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
  font-size: 5rem;
  cursor: pointer;
}
.backgroundBluredForTheVideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.5px) !important;
  text-decoration: none;
  z-index: 1;
}
.videoContaint {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.videoDiv {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: contain;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media (min-width: 576px) {
  .cardWidth {
    flex: 0 0 auto;
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .cardWidth {
    flex: 0 0 auto;
    width: 25% !important;
  }
}

@media (min-width: 1400px) {
  .cardWidth {
    flex: 0 0 auto;
    width: 20% !important;
  }
}
