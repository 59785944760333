.buttonStyle {
  margin-top: 7px;
  min-width: 100px;
}

.separator {
  width: calc(100% + 1.75rem * 2);
  left: -1.75rem;
}

.svgZoomIcon svg {
  fill: #a1a5b7;
}
.svgLinkIcon svg {
  fill: #a1a5b7;
}
.dialog {
  max-width: 935px !important;
}
.preview {
  object-fit: cover;
}

.modal{
  max-width: 1035px !important;
}
