.label {cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: #9899AC;
  padding: 20px;
}

.active {
  color: #000000;
  border-bottom: 2px solid #009EF7;
}
.wrapper {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #EFF2F5;
}

