.fitContent {
  width: 'fit-content !important';
  // DESKTOP VIEW
  @media (min-width: 1000px) {
    width: 'fit-content !important';
    min-width: 1000px;
  }
  overflow: hidden !important;

  width: 100%;
}

.fitContentOld {
  width: fit-content !important;
}

.maxWidthNone {
  max-width: none !important;
}
