.card0 {
    background-color: #F5F5F5;
    border-radius: 8px;
}

.card1 {
    z-index: 0;
    /* border-right: 1px solid #F5F5F5 */
}

.card2 {
    display: none
}

.card2.show {
    display: block
}

.progress-bar {
    background-color: #2522aa;
    font-weight: bold;
}

#progressbar {
    position: relative;
    padding-left: 0;
    color: #2522aa;
    display: flex;
    flex-flow: column;
    justify-content: left;
    align-items: flex-start;
}

#progressbar li {
    list-style-type: none;
    font-size: 1.3rem;
    display: flex;
    color: #000;
    font-weight: 500;
    width: 100%;
    align-items: center;
}
#progressbar.nav-tabs .nav-link{
    transition: none !important;
}
#progressbar.nav-tabs .nav-link:hover {
    border-right: 1px solid #fff !important;
}
#progressbar.nav-tabs .nav-link.active:hover {
    /* border: 1px solid #ddd !important; */
    border-right: 1px solid #fff !important;
}

#progressbar.nav-tabs .nav-link:hover {
    border: none;
    /* border-right: 1px solid #ddd !IMPORTANT; */
}
#progressbar.nav-tabs .nav-link:hover {
    border: 1px solid #fff;
}
#progressbar .step0:before {
    content: "";
    color: #fff
}

#progressbar li:before {
    width: 19px;
    height: 17px;
    line-height: 30px;
    display: block;
    font-size: 20px;
    background: #fff;
    border: 2px solid #d9178a;
    border-radius: 50%;

    margin-right: 10px;
}

#progressbar li:nth-child(3)::after {
    top: 30px
}

#progressbar li:nth-child(2)::after {
    top: 0px
}

#progressbar li:first-child:after {
    position: absolute;
    top: -81px
}

#progressbar li.active:after {
    background: #d9178a;
}
#progressbar.nav-tabs .nav-link {
    /* border-right: 1px solid #ddd !important; */
    width: 100% !important;
}
#progressbar li.active:before {
    background: #d9178a;
    content: "\f00c";
    font-family: "Font Awesome 5 free";
    top: 0;
    left: 0;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tick {
    width: 100px;
    height: 100px
}
#progressbar {
    margin-right: 0 !important;
    border-right: 1px solid #ddd !important;
}
.progress{
    height: 20px !important;
    margin-bottom: 25px !important;
}

#progressbar.nav-tabs .nav-link {
   
    margin-bottom: -4px;
    border-radius: 0 !important;
}
#progressbar.nav-tabs .nav-link.active {
    border: 1px solid #ddd;
    position: relative;
    border-radius: 0 !important;
    border-right: 1px solid #fff !important;
    margin-right: -1px;
}
.tab-content{
    margin: auto;   
}

@media (max-width: 768px) {
    .tab-content{
        margin-top: 30px;
        margin-left: -78px;   
    }
}
.tab-content > .active {
    position: relative;
    padding-left: 15px;
}  
#progressbar.nav-tabs .nav-link a {
    /* border-right: 1px solid #ddd !important; */
    width: 100% !important;
}
#progressbar.nav-tabs .nav-link {
    width: 100% !important;
    margin-top: 1px;
}
#progressbar.nav-tabs .nav-link.active {
    width: 100% !important;
    border-right: none !important;
}
@media screen and (max-width: 912px) {
    .card00 {
        padding-top: 30px
    }

    .card1 {
        border: none;
        margin-left: 50px
    }

    .card2 {
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 25px
    }

    .social {
        height: 30px;
        width: 30px;
        font-size: 15px;
        padding-top: 8px;
        margin-top: 7px
    }

    #progressbar {
        left: -25px
    }
}
a.nav-link.text-dark.active, a.nav-link.text-dark.active i {
    color: #dc2a93 !important;
}
a.nav-link.text-dark i {
    display: none;
}
a.nav-link.text-dark.active i {
    display: inline;
}