body {
  font-family: Montserrat, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import "~video-react/styles/scss/video-react";
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/montserrat/Montserrat-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}

.formik-error-message {
  display: block !important;
}

// Bootstrap modal fix
.modal-open {
  overflow: inherit !important;
  padding-right: 0 !important;
}

.separator {
  &.separator-content {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 0;

    &::after,
    &::before {
      content: ' ';
      width: 50%;
      border-bottom: 1px solid #eff2f5;
    }

    &::before {
      margin-right: 1.25rem;
    }

    &::after {
      margin-left: 1.25rem;
    }
  }
}

body > tr {
  display: table !important;

  td {
    padding: 0.75rem 0.75rem;
    display: table-cell;
    vertical-align: middle;
  }
  td:first-child {
    padding-left: 0;
  }
  td:last-child {
    padding-right: 0;
  }
}

.mt-40px {
  margin-top: 40px;
}

.override-input-search-field-width-file-manager,
.override-input-search-field-width-youtube-app-container {
  width: 100% !important;
}

.progress-bar[aria-valuenow='0'] {
  transition: none;
}

// Bootstrap tooltip colors
.tooltip-inner {
  background-color: black !important; /* Set box color */
  color: white !important; /* Set text color */
}

.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: black; /* Set arrow color */
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: black; /* Set arrow color */
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: black; /* Set arrow color */
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: black; /* Set arrow color */
}

// iframe {
//   pointer-events: none;
// }

.capital {
  text-transform: capitalize;
}
