.backdrop {
  background-color: transparent;
  z-index: 999 !important;
}

.spinner {
  z-index: 1000 !important;
}

.backdropOverlap {
  z-index: 1000 !important;
}

.spinnerOverlap {
  z-index: 1001 !important;
}
