.image_background {
  background-position: center !important;
  background-size: 100% !important;
  border-radius: 0.475rem !important;
}

.imageThumbnail {
  object-fit: contain;
}

.apps_icon i {
  font-size: 20px;
}
