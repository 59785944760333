.wrapper {
    height: 40px;
    background-color: #5D5FEF;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 900;
    width: 100%;
}

.text {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}
.buttonWrapper {
    position: fixed;
    z-index: 999;
    right: 0;
    background-color: #5D5FEF;
}

.button {
    background-color: rgba(0, 0, 0, 0.2);
    width: 71px;
    height: 28px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 6px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 11px;
    cursor: pointer;


}
