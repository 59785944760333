.htmlIframe {
  padding: 4px;
  pointer-events: none;
}

.scheduleBtn {
  margin-left: 5px;
}
.BtnApercu {
  margin-left: 5px;
}
.instagramImage {
  max-height: 80px;
  margin: 0 auto 0 auto;
  width: auto;
}

.instagramIcImage {
  width: 45px;
  height: 45px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px 0 0 3px;
  background: #fff;
  &:focus {
    outline: none;
  }
  :hover {
    outline: none;
  }
  &::after {
    box-sizing: border-box;
  }
  &::before {
    box-sizing: border-box;
  }
}
.textInstagramLogin {
  width: 100%;
  padding: 0 20px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }
  :hover {
    outline: none;
  }
  &::after {
    box-sizing: border-box;
  }
  &::before {
    box-sizing: border-box;
  }
}
.googleBtn {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  color: #fff;
  border-radius: 5px;
  border: 2px solid #dc2743 !important;
  padding: 0;
  width: fit-content;
  min-width: 40%;
  &:hover {
    color: rgb(240, 240, 240);
  }
}
.instagramLogin {
  background-color: transparent;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  color: #fff !important;
  border: 0px;
  border-radius: 2px;
  height: auto;
  width: 50%;
  padding: 10px 20px !important;
  margin: auto;
  min-height: 7vh;
  cursor: pointer;
  font-size: 15px;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  border-radius: 8px;
  color: white;
  &:hover {
    background-color: #324e85;
  }
  &:disabled {
    margin: auto;
    background-color: #4c6390 !important;
    width: 30%;
    cursor: not-allowed;
    color: #fff !important;
    i {
      color: #fff;
      font-size: 18px;
      margin: 0px 5px !important;
    }
  }
  i {
    color: #fff;
    font-size: 18px;
    margin: 0px 10px;
  }
}
.instagramLoginAPI {
  height: auto;
  width: 30%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
i {
  color: #fff;
  font-size: 18px;
  margin: 0px 10px;
}
