.workplaceLibraryWrap {
  .workplaceLibraryContent {
    display: flex;
    box-shadow: 1px 1px 20px 0 rgb(9 29 54 / 30%);
    background: #fff;
    border-radius: 10px;
    @media (max-width: 767px) {
      display: block;
      .cardLeft,
      .cardRight {
        width: 100% !important;
        margin: 20px 0px !important;
        z-index: 1;
      }
      .overlayImage {
        img {
          transform: translate(30px, -30px) !important;
          overflow: hidden;
          z-index: 0;
          width: 140px !important;
        }
      }
    }
    .cardLeft {
      padding: 57px;
      text-align: left;
      position: relative;
      width: 50%;
      display: flex;
      align-items: center;
      .mainHeading {
        text-align: left;
        width: 100%;
        font-size: 43px;
        color: #1e22aa;
        margin-bottom: 30px;
        display: block;
        font-weight: bold;
        line-height: 63px;
      }
      button {
        background: #e9008f;
        color: #fff;
        padding: 10px 46px;
        width: 100%;
        font-size: 25px;
        margin-top: 20px;
        font-weight: 500;
        border-radius: 5px;
      }
      .secContent {
        font-size: 16px;
        line-height: 1.8;
      }
      @media (max-width: 1300px) {
        padding: 30px;
        .secContent {
          font-size: 14px;
        }
        button {
          font-size: 25px;
        }
        .mainHeading {
          font-size: 27px;
          line-height: inherit;
        }
      }
    }
    .cardRight {
      z-index: 0;
      overflow: hidden;
      @media only screen and (max-width: 768px)  {
        margin-bottom: 350px !important;
      }
      &.card {
        width: 50%;
        position: relative;
        display: flex;
        align-items: center;
        .libraryImage {
          img {
            width: 100%;
          }
          .overlayImage {
            position: absolute;
            right: 0;
            top: 0;
            img {
              width: 222px;
              transform: translate(37px, -37px);
              overflow: hidden;
              z-index: -1;
              position: relative;
            }
          }
        }
      }
    }
  }
}
