.userGroupBlockInfo {
  min-height: 213px;
  min-width: 277px !important;
  height: 100%;
  margin-right: 20px;
}

.userGroupName {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 16px;
}

.location {
  color: #A1A5B7;
  font-size: 14px;
  margin-bottom: 14px;
}

.location span {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-top: 4px;
}

.footerButtons {
  margin-top: 30px;
}

.editButton {
  font-size: 14px !important;
  color: #9899AC !important;
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

.removeButton {
  font-size: 15px !important;
  background-color: rgba(241, 65, 108, 0.15) !important;
}

.div {
  cursor: pointer;
}

