.userSelect {
  width: 'fit-content';
  min-width: 180px !important;

  // MOBILE RESPONSIVE
  @media (max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.deviceBlockInfo {
  min-height: 213px;
  height: 100%;
  margin-right: 20px;
}

.deviceGroupName {
  font-size: 17px;
  font-weight: 600;
  margin-right: 100px;
}

.deviceGroupCreatedDate {
  color: #a1a5b7;
  font-size: 14px;
}

.deviceGroupCreatedDate span {
  font-weight: 600;
  color: #000;
}

.editButton {
  font-size: 14px !important;
  color: #9899ac !important;
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

.removeButton {
  font-size: 15px !important;
  background-color: rgba(241, 65, 108, 0.15) !important;
}

.tooltipDeviceGroup {
  font-size: 16px;
  margin-left: 5px;
}
