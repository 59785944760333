.preview {
  object-fit: cover;
  border-radius: 0.37rem !important;
}

.tagsContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.tag {
  padding: 2px 13px;
  margin: 0px 2px;
  border-radius: 6px;
  border: 1px solid #7239ea;
  color: #7239ea;
}

.layoutTemplateText {
  color: #CD8C50;
}
