.tableWrapper {
  padding: 30px;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.tableWidth{
    padding: 30px;
    margin-left: 10px;
    border-radius: 10px;
    margin-top: 10px;
    width: 100%;  
}
.tableRight {
  flex-direction: row;
  background-color: white;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 30px 0 0;
}

.badgeStyle {
  font-size: 12px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
