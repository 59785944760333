.btn {
  width: fit-content;
  letter-spacing: 1px;
  border-radius: 20px !important;
  cursor: auto !important;
}

.title {
  font-size: 1.3rem;
  min-height: 55px;
}

.icon {
  width: 228px;
  height: 91px;
  object-fit: contain;
}

.exploreButton {
  background-color: #5b6770 !important;
  color: #fff !important;
  cursor: pointer;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0,0,0,.32),0 1px 2px rgba(0,0,0,.24)!important;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.shadow:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.nameWithBackGroundInFooterOfCard {
  background-color: #fff;
  color: #1c24ac;
  border-radius: 0 0 5px 5px;
  width: 100%;
  height: 38px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
}
.image {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-width: 250px;
  }
}
.body {
  // display: flex;
  // justify-content: center;
  // align-items: stretch;
  height: 100%;
  // max-height: 250px;
  // min-height: 250px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    // min-width: 200px;
   
  }
}
.overlayImage {
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
  img {
    min-height: 3.6vw;
    width: auto!important;
    height: 3.6vw!important;
    -webkit-transform: translate(1%,-8%);
    transform: translate(1%,-8%);
    object-fit: contain;
    z-index: -1;
    position: relative;
  }
}

//all of this can be improved if we just make the height relative to the card width with refs in code(exp text responsiveness)
@media (max-width: 319px){
  
  .overlayImage {
 
    img {
     
      min-height: 20vw;
    }
  }
    
}
@media (min-width: 320px){
  
  .overlayImage {
 
    img {
     
      min-height: 80px;
    }
  }
    
}
@media (min-width: 416px){
  
  .overlayImage {
 
    img {
     
      min-height: 100px;
    }
  }
    
}


@media (min-width: 576px){
  .cardWidth{
    flex: 0 0 auto;
    width: 100% !important;
    height: 100%;
  } 
  .overlayImage {
 
    img {
     
      min-height: 12vw;
    }
  }
}
@media (min-width: 768px){
  
  .overlayImage {
 
    img {
     
      min-height: 3.5vw;
    }
  }
    
}
@media (min-width: 1200px){
  .cardWidth{
    flex: 0 0 auto;
    width: 100% !important;
  }
}

@media (min-width: 1400px){
  .cardWidth{
    flex: 0 0 auto;
    width: 100% !important;
  }
}
@media (min-width: 1920px){
  .nameWithBackGroundInFooterOfCard {
   font-size: 0.8vw;
    height: 2vw;
   
  }
}