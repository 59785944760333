.dialog {
  max-width: 935px !important;
}

.preview {
  object-fit: cover;
}
.modal {
  max-width: 1035px !important;
}
