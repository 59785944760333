.tickerContainer {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;

  &:hover .tickerItem {
    animation-play-state: paused;
  }
  & .tickerItem {
    display: inline-block;
    padding-left: 100%;
    animation: tickering 5s linear infinite;
    cursor: default;
  }
}

/* Make it move */
@keyframes tickering {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
