.warraper {
  @media (min-width: 600px) {
    width: 20% !important;
    max-width: 60%;
  }

  @media (max-width: 600px) {
    width: 100% !important;
    max-width: 60%;
  }

  img {
    opacity: 0.85;
    -webkit-filter: grayscale(100%);
    min-width: 50%; /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    width: 100%;
    height: auto;
    @media (min-width: 1600px) {
      width: 80%;
      height: auto;
    }
  }
}
.image {
  @media (min-width: 1600px) {
    width: 50%;
    height: auto;
  }
}

.imageHome {
  width: 58%;
  height: auto;
}
.leftpart {
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    div {
      display: none !important;
    }
  }
}
.background {
  @media only screen and (-webkit-min-device-pixel-ratio: 4) {
    width: 100%;
  }
  @media (min-width: 1600px) {
    img {
      max-width: 1200px;
    }
    .warraper {
      img {
        max-width: 600px;
      }
    }
  }
}
.brands {
  @media (min-width: 1600px) {
    max-width: 1200px;
  }
}

.title {
  @media (min-width: 1600px) {
    .text-primary {
      font-size: 40pt !important;
    }
    font-size: 20pt !important;
  }
}
