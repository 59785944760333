.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none!important;
}
.btn.btn-outline:not(.btn-outline-dashed) {
    border: 1px solid #e4e6ef;
}
.btn.btn-text-gray-700 {
    color: var(--kt-text-gray-700);
}

.btn.btn-flex {
    display: inline-flex;
    align-items: center;
}
.btn.btn-purple{
    background-color: #d9178a;
    color: #fff;
}
.btn.btn-purple:hover{
    color: #fff;
}

.formLogo {
    display: none;
}

@media screen and (max-width: 769px) {
    .formLogo{
        display: block !important;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .formLogo{
        display: block !important;
    }
}