.feedsMenu {
    z-index: 1055;
    max-width: 402.5px;
    width: 100%;
}

.customFeedMenu {
    width: 90%;
}

.scheduleBtn{
    margin-left: 5px;
}