.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
    box-shadow: none!important;
}
.btn.btn-outline:not(.btn-outline-dashed) {
    border: 1px solid #e4e6ef;
}
.btn.btn-text-gray-700 {
    color: var(--kt-text-gray-700);
}

.btn.btn-flex {
    display: inline-flex;
    align-items: center;
}
div#kt_body {
    height: 100vh !important;
}
.btn.btn-purple{
    background-color: #d9178a;
    color: #fff;
}
.btn.btn-purple:hover{
    color: #fff;
}

.right-form-indigo {
    max-height: 86vh;
    overflow: auto;
    height: fit-content;
}

form#kt_sign_up_form {
    padding-bottom: 20px;
}
.leftColumn{
    display: flex !important;
}
@media screen and (max-width: 769px) {
    .leftColumn{
        display: none !important;
    }
}