.htmlIframe{
    padding: 4px;
}

.scheduleBtn{
    margin-left: 5px;
}

.loadBtns{
    margin-left: 10px;
}

.successIcon{
    color: green !important;
    padding-left: 5px;
    padding-bottom: 2px;
    font-size: 15px !important;
}