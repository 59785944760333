.responsiveUpgradeButton {
  @media (max-width: 768px) {
    span {
      display: none;
    }
   button {
    width: 5px !important;
    font-size: 5px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    span{
        display: none;
    }
    i{
        color: white;
    }
   }
  }

  @media (min-width: 768px) {
    i{
        display: none !important;
    }
  }
    
}