.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: none !important;
}
.btn.btn-outline:not(.btn-outline-dashed) {
  border: 1px solid #e4e6ef;
}
.btn.btn-text-gray-700 {
  color: var(--kt-text-gray-700);
}

.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}
.btn.btn-purple {
  background-color: #d9178a;
  color: #fff;
}
.btn.btn-purple:hover {
  color: #fff;
}

.formLogo {
  display: none;
}

@media screen and (max-width: 769px) {
  .formLogo {
    display: block !important;
  }
}

.hs-input {
  padding-top: 15px;
  padding-bottom: 15px;
  border-style: solid;
  border-width: 1.5px;
  border-color: #f7f7f7;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  font-family: Ubuntu, Helvetica, sans-serif;
  font-style: italic;
}
.hs-input:focus {
  border-color: #d9178a;
  outline: 0;
}
.hs-button {
  padding: 0 !important;
  height: 55px;
  display: inline-block;
  /* border-radius: 100px !important; */
  /* -webkit-border-radius: 100px; */
  background-color: #d9178a;
  /* box-shadow: 0 -1px 12px 4px rgb(217 23 138 / 50%); */
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  /* font-weight: 700; */
  text-align: center;
  text-decoration: none;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  border: 0;
  color: white;
}

.hubspot-inline {
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 4fr;
  grid-template-columns: 3fr 185px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .hubspot-inline {
    display: block;
  }
  .hubspot-inline .hs-button {
    left: 0px !important;
    background-position: 70% 50% !important;
  }
  .hubspot-inline .hs-input,
  .hubspot-popup .hs-input {
    /* border-radius: 100px; */
  }
}
.hubspot-inline .hs-input,
.hubspot-popup .hs-input {
  /* box-shadow: rgb(0 0 0 / 20%) 0px 2px 10px; */
  height: 55px;
  /* border-top-left-radius: 100px;
  border-bottom-left-radius: 100px; */
}
.hs-button:hover {
  /* transform: scale(1.05); */
  cursor: pointer;
}
.hubspot-popup .hs-input {
  /* border-radius: 100px; */
  margin-bottom: 25px;
}
.hubspot-inline .hs-button {
  position: relative;
  left: -45px;
  padding-right: 20px !important;
  background-position: 80% 50%;
}

.hubspot-inline .hs_error_rollup,
.hubspot-inline .no-list {
  display: none;
}

.hubspot-popup .hs_error_rollup ul,
.hubspot-popup .no-list ul {
  list-style: none;
}
.hubspot-popup .hs_error_rollup *,
.hubspot-popup .no-list * {
  font-size: 0.8rem;
  font-weight: 500;
}
