.mode {
  min-width: 120px;
  max-width: 200px;
  padding: 10px 16px;
  height: 44px;
  color: #9899AC;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: rgba(1, 159, 247, 0.07);
    color: #009EF7;
    font-weight: bold;
  }
}

.separator {
  height: 23px;
  margin-bottom: 24px;
  border-bottom: 1px dashed #D2D3D8;
}

.errorMessage {
  min-height: 30px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #FF0000;
  border-radius: 6px;
  color: #FF0000;
}

.templateImage{
  margin-top: 10px;
  width: 100%;
  height: auto;
  min-height: 220px;
}

.scheduleBtn{
  margin-left: 5px;
}