.dialog {
    width: 100% !important;
}
  
.preview {
    @extend .dialog;
  
    height: 56vw !important;
    max-height: 450px !important;
    padding-bottom: 0 !important;
}
  