.countThimbnail {
  z-index: 100;
  width: 50px;
  height: 30px;
  background-color: #fff0;
}

.countIframe {
  z-index: 10;
  margin-right: 5px;
  border-radius: 5px;
}
