.preview {
  object-fit: contain;
  border-radius: 0.37rem !important;
}

.ant-image-preview-operations-operation {
  &:not(:first-child) {
    display: none;
  }
}
