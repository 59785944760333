.button {
  color: rgb(126, 130, 153);
  border: none;
  background-color: transparent;
  padding: 0;
}
.image {
  min-width: 40px;
  background-size: cover;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 95%;
}
