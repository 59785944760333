.htmlIframe {
  padding: 4px;
  pointer-events: none;
}

.scheduleBtn {
  margin-left: 5px;
}
.BtnApercu {
  margin-left: 5px;
}
.msTeamsImage {
  max-height: 100px;
  margin: 0 auto 0 auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.msTeamsLogin {
  background-color: transparent;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);

  border: 0px;
  border-radius: 2px;
  width: fit-content !important;
  max-width: 80%;
  padding: 10px 20px !important;
  margin: 0.25em 0em;

  font-size: 15px;

  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wpo365mssigninbutton {
  border: 1px solid #8c8c8c;
  background: #2f2f2f;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
  max-height: 41px;
  min-height: 41px;
  height: 41px;
}
.wpo365mssigninlogo {
  padding-left: 12px;
  padding-right: 6px;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  flex-shrink: 1;
  width: 21px;
  height: 21px;
  box-sizing: content-box;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.wpo365mssigninlabel {
  padding-left: 6px;
  padding-right: 12px;
  font-weight: 600;
  color: #ffffff;
  font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans',
    'Helvetica Neue', Arial, sans-serif;
  font-size: 15px;
  -webkit-flex-shrink: 1;
  -moz-flex-shrink: 1;
  flex-shrink: 1;
  height: 21px;
  line-height: 21px;
}
