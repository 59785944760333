.days {
  width: 150px;
}

.days2 {
  width: 80px;
}

.activeDay {
  width: 21px;
  height: 21px;
  border-radius: 28px;
}

.activeDay span {
  color: #009ef7 !important;
}

.row {
  width: 1000px;
}

.dragHandle {
  margin-right: 10px;
  padding-bottom: 2px;
  cursor: grab;
}

.thumbnail {
  border-radius: 0.475rem;
  width: 50px;
  height: 30px;
  overflow: hidden;
}

.type_icon {
  font-size: 15px;
  padding-left: 6px;
}

.iframePreview {
  width: 1613px;
  height: 968px;
  position: absolute;
  top: -469px;
  left: -782px;
  transform: scale(.031);
  border-radius: 173px;
  pointer-events: none;
}