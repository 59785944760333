.separator {
  width: calc(100% + 1.75rem * 2);
  left: -1.75rem;
}

.svgZoomIcon svg {
  fill: #a1a5b7;
}
.svgLinkIcon svg {
  fill: #a1a5b7;
}
