.htmlIframe {
  padding: 4px;
  pointer-events: none;
}

.scheduleBtn {
  margin-left: 5px;
}
.BtnApercu {
  margin-left: 5px;
}
.facebookImage {
  max-height: 80px;
  margin: 0 auto 0 auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.facebookLogin {
  background-color: transparent;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  color: #fff !important;
  border: 0px;
  border-radius: 2px;
  width: fit-content !important;
  max-width: 80%;
  padding: 10px 20px !important;
  margin: 0.25em 0em;
  cursor: pointer;
  font-size: 15px;
  background: #1877f2;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #1877f2;
  }
  &:disabled {
    margin: auto;
    background-color: #1877f2 !important;
    width: 30%;
    cursor: not-allowed;
    color: #fff !important;
    i {
      color: #fff;
      font-size: 18px;
      margin: 0px 5px !important;
    }
  }
  i {
    color: #fff;
    font-size: 18px;
    margin: 0px 10px;
  }
}
